import { Button, Card, Input } from 'antd';
import React, { useState } from 'react';
import { ReportsComponent } from './ReportsComponent';
import { MonthlyBreakdown, TransactionsTable } from './MobileFriendly';
import { RouteComponentProps } from 'wouter';

export interface YearlyIncomingReportComponentProps {
    accountId: string;
}

export interface YearlyIncomingReportComponentState {
    error?: string;
}

interface YearlyIncomingReport {
    monthlyBreakdown: { [mm: string]: MonthlyIncomingReport };
    overall: {
        categories: { [key: string]: number },
        total: number,
        maisieOwes: number
        marcOwes: number
    };
}

interface MonthlyIncomingReport {
    report: {
        categories: { [key: string]: number },
        total: number,
        transactions?: ReportTransaction[]
    }
    transactionsUpdatedAt: string
}

interface ReportTransaction {
    id: string
    timestamp: string
    info: string
    description: string
    amount: number // credit transfers are +ve
    category: string;
    metadata?: { [key: string]: string }
}


let gbpFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});
function asGbp(amount?: number): string {
    if (amount === undefined) return 'No data'
    return gbpFormat.format(amount)
}


const SetCreditType = ({ current, onSetCreditType }
    : { current: string, onSetCreditType: (creditType: string) => void }) => {
    const [creditType, updateCreditType] = useState(current)

    return <div>
        <span>Update credit type</span>
        <Input style={{ width: '20%' }} value={creditType} onChange={val => {
            val.preventDefault()
            const value = (val.target as any).value
            updateCreditType(value)
        }} />
        <Button onClick={() => onSetCreditType(creditType)}>Update</Button>
    </div>
}

export const YearlyIncomingReportComponent = (props: RouteComponentProps<{ accountId: string }>) => {

    const accountId = props.params.accountId

    if (!accountId) {
        return <>No account ID selected</>
    }

    return <ReportsComponent<YearlyIncomingReport>
        accountId={accountId}
        reportName='IncomingTransactions'
        renderComponent={({ report, updateMetadata }) => <>
            <h2>Overall</h2>
            <Card>
                <div><b>Total</b> = {asGbp(report.overall.total)}</div>
                <div><b>Maisie owes</b> = {asGbp(report.overall.maisieOwes)}</div>
                <div><b>Marc owes</b> = {asGbp(report.overall.marcOwes)}</div>
                <div><b>Category breakdown</b>
                    <ul>
                        {Object.keys(report.overall.categories || {}).map(category => <li key={category}>{category} = {asGbp(report.overall.categories[category])}</li>)}
                    </ul>
                </div>
            </Card>

            <MonthlyBreakdown
                monthlyBreakdown={report.monthlyBreakdown}
                renderSummary={tableDataRow => <>
                    {Object.keys(tableDataRow.report.categories).map(category => <div key={category}><b>{category}</b> = {asGbp(tableDataRow.report.categories[category])}</div>)}
                </>}
                renderContents={row => <TransactionsTable
                    showSubCategory={false}
                    transactions={row.report.transactions || []}
                    renderEditable={row => <SetCreditType
                        current={(row.metadata || {})['credit-type']}
                        onSetCreditType={category => updateMetadata({
                            yyyyMm: row.timestamp.substring(0, 7),
                            transactionId: row.id,
                            metadataKey: 'credit-type',
                            metadataValue: category,
                        })} />}
                />}
            />
        </>}
    />
}

