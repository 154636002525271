import React from 'react';
import { Button } from 'antd';
import { CancelToken } from '../api/HttpClient';
import { AccessTokenInfo, BankLoginStatusV2, TransactionApiV2 } from '../api/TransactionApi';
import { logger } from '../utils';

export interface AccessTokenComponentProps {
    accessToken: AccessTokenInfo,
}

export interface AccessTokenComponentState {
    loginStatus?: BankLoginStatusV2
    error?: string
    exchangingCode?: string
    updatingTransactions: boolean
    attemptedRefreshError?: string
    isRefreshing: boolean
}

class AccessTokenComponent extends React.Component<AccessTokenComponentProps, AccessTokenComponentState> {
    constructor(props: AccessTokenComponentProps) {
        super(props)
        this.state = {
            updatingTransactions: false,
            isRefreshing: false,
        };
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    refresh() {
        const { accessToken } = this.props
        const { isRefreshing } = this.state
        if (isRefreshing) return
        this.setState({ isRefreshing: true })
        TransactionApiV2.bank.refresh(accessToken.account_id)
            .then(response => {
                if (response.statusCode === 200) {
                    window.location.href = '/'
                } else {
                    this.setState({ isRefreshing: false, attemptedRefreshError: (response.data as any).error_code || ':(' })
                }
            })
            .catch(err => {
                logger.error('exchangeCode error', err);
            });
    }

    exchangeCode(code: string) {
        const { exchangingCode } = this.state
        if (code === exchangingCode) return
        this.setState({ exchangingCode: code })
        TransactionApiV2.bank.exchangeCode(code)
            .then(response => {
                if (response.statusCode === 200) {
                    window.location.href = '/'
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                logger.error('exchangeCode error', err);
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    render() {
        const { accessToken } = this.props
        const { error, attemptedRefreshError, isRefreshing } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        const accessTokenDate = new Date(accessToken.access_token_expiry)
        const accessTokenExpired = new Date().getTime() - accessTokenDate.getTime() > 0
        const accesstokenExpiry = accessTokenDate.toLocaleString()

        const queryString = window.location.href.split('?')[1]
        if (!!queryString) {
            const params: { [key: string]: string } = {}
            queryString.split('&').forEach(kv => {
                const [key, value] = kv.split('=')
                params[key] = value
            })
            const code = params['tl_code_v2']
            if (!!code)
                this.exchangeCode(code)
        }

        return <div>
            <h3>{accessToken.account_id}</h3>
            {accessTokenExpired && <>
                <div>Access token expired at <b>{accesstokenExpiry}</b></div>
                {!attemptedRefreshError && <>
                    {isRefreshing && <div>waiting</div>}
                    {!isRefreshing && <Button onClick={() => this.refresh()}>Refresh Token</Button>}
                </>}
                {!!attemptedRefreshError && <>
                    <div>Could not refresh: {attemptedRefreshError}</div>
                    <div>Create new access token below</div>
                </>}
            </>}
            <div>Access token expires at <b>{accesstokenExpiry}</b></div>
        </div>
    }
}

export interface TrueLayerStatusV2ComponentProps {
}

export interface TrueLayerStatusV2ComponentState {
    loginStatus?: BankLoginStatusV2
    error?: string
    exchangingCode?: string
    updatingTransactions: boolean
    attemptedRefreshError?: string
    refreshingAccounts: { [accountId: string]: boolean }
}

export class TrueLayerStatusV2Component extends React.Component<TrueLayerStatusV2ComponentProps, TrueLayerStatusV2ComponentState> {
    constructor(props: TrueLayerStatusV2ComponentProps) {
        super(props)
        this.state = {
            updatingTransactions: false,
            refreshingAccounts: {},
        };
    }

    componentDidMount(): void {
        this.loadLoginStatus()
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    loadLoginStatus() {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        TransactionApiV2.bank.loginStatus(this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    this.setState({
                        loginStatus: response.data
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    exchangeCode(code: string) {
        const { exchangingCode } = this.state
        if (code === exchangingCode) return
        this.setState({ exchangingCode: code })
        TransactionApiV2.bank.exchangeCode(code)
            .then(response => {
                if (response.statusCode === 200) {
                    window.location.href = '/'
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                logger.error('exchangeCode error', err);
            });
    }

    // // todo: delete me?
    // updateTransactions() {
    //     TransactionApiV2.bank.updateTransactions('2024-11')
    //         .then(response => {
    //             this.setState({ updatingTransactions: false })
    //         })
    //         .catch(err => {
    //             logger.error('updateTransactions error', err);
    //             this.setState({ updatingTransactions: false })
    //         });
    //     this.setState({ updatingTransactions: true })
    // }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    render() {
        const { loginStatus, error } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (!loginStatus) {
            return <>Waiting</>
        }

        const queryString = window.location.href.split('?')[1]
        if (!!queryString) {
            const params: { [key: string]: string } = {}
            queryString.split('&').forEach(kv => {
                const [key, value] = kv.split('=')
                params[key] = value
            })
            const code = params['tl_code_v2']
            if (!!code)
                this.exchangeCode(code)
        }

        return <>
            {/* <h2>Linked access tokens</h2>
            {loginStatus.accounts.map(account => <AccessTokenComponent key={account.account_id} accessToken={account} />)}
            {loginStatus.accounts.length === 0 && <div>No access token found</div>} */}
            <h3>Link new accounts - V2</h3>
            <Button href={loginStatus.auth_url}>Create new access token</Button>
        </>
    }
}
