import { Divider, List, Table } from "antd"
import { isMobile } from '../../utils';
import { ColumnFilterItem } from "antd/es/table/interface";
import { EditOutlined, UndoOutlined } from "@ant-design/icons";
import { useState } from "react";

let gbpFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});
function asGbp(amount: number | undefined): string {
    if (amount === undefined) return 'No Value'
    return gbpFormat.format(amount)
}

function asDate(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toDateString() + ' ' + date.toLocaleTimeString()
}

function getFilters<T>(transactions: T[], getFilterKey: (transaction: T) => string): ColumnFilterItem[] {
    const categories: { [key: string]: boolean } = {}
    transactions.forEach((t) => categories[getFilterKey(t)] = true)

    return Object.keys(categories).map(category => ({ text: category, value: category }))
}


interface MonthlyReportTransaction {
    id: string
    timestamp: string
    description: string
    subCategory?: string
    category: string
    amount: number
    metadata?: { [key: string]: string }
}

export const ListItem = <T extends MonthlyReportTransaction>(props: {
    item: T,
    renderEditable: (transaction: T) => React.ReactNode,
}) => {

    const [isEditing, setIsEditing] = useState(false)

    return (
        <List.Item actions={[
        ]}>
            <List.Item.Meta
                title={<div>
                    <div style={{ display: 'inline-block', float: 'right' }} onClick={() => setIsEditing(!isEditing)}>{isEditing ? <><UndoOutlined /> Cancel</> : <><EditOutlined /> Edit</>}</div>
                    <div style={{ display: 'inline-block' }}>{props.item.description}</div>
                </div>}
                description={asDate(props.item.timestamp)}
            />
            <div><b>Category:</b> {props.item.category}</div>
            <div><b>Amount:</b> {asGbp(props.item.amount)}</div>
            {!!props.item.metadata && <>
                <div><b>Current Metadata:</b></div>
                <ul>
                    {Object.keys(props.item.metadata).map(metadataKey => <li>{metadataKey} = {(props.item.metadata || {})[metadataKey]}</li>)}
                </ul>
            </>}
            {isEditing && <>
                <Divider />
                {props.renderEditable(props.item)}
            </>}
        </List.Item>
    )
}

export const TransactionsTable = <T extends MonthlyReportTransaction>(props: {
    showSubCategory: boolean,
    transactions: T[],
    renderEditable: (transaction: T) => React.ReactNode,
}) => {

    if (isMobile) {
        return <List
            itemLayout="vertical"
            pagination={{}}
            dataSource={props.transactions}
            renderItem={(item, index) => <ListItem item={item} renderEditable={props.renderEditable} />}
        />
    }

    return <Table rowKey={t => t.timestamp} dataSource={props.transactions}
        expandable={{
            expandedRowRender: row => props.renderEditable(row),
            rowExpandable: record => !record.id.startsWith('fudge')
        }}
    >
        <Table.Column title="Timestamp" dataIndex={['timestamp']} key="timestamp" />
        <Table.Column
            title="Category"
            dataIndex={['category']}
            key="category"
            filters={getFilters(props.transactions, t => t.category)}
            onFilter={(value, record: T) => record.category === value}
        />
        {props.showSubCategory && <Table.Column
            title="Category Type"
            dataIndex={['subCategory']}
            key="subCategory"
            filters={getFilters(props.transactions, t => t.subCategory ?? '')}
            onFilter={(value, record: T) => record.subCategory === value}
        />}
        <Table.Column title="info" dataIndex={['info']} key="info" />
        <Table.Column
            title="Description"
            dataIndex={['description']}
            key="description"
            filters={getFilters(props.transactions, t => t.description)}
            onFilter={(value, record: T) => record.description === value}
        />
        <Table.Column title="Amount" dataIndex={['amount']} key="amount" render={amount => asGbp(amount)} />
    </Table>
}
