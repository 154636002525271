import { Collapse, Table } from "antd"
import { isMobile } from '../../utils';

interface MonthlyReportTransaction {
}

interface MonthlyReportTableData<T> {
    mm: string
    friendlyUpdatedAt: string
    report: T
}

interface MonthlyReport {
    transactionsUpdatedAt: string
    report: {
        total: number,
        transactions?: MonthlyReportTransaction[]
    }
}

function asDate(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toDateString() + ' ' + date.toLocaleTimeString()
}

function asTableData<T extends MonthlyReport>(monthlyBreakdown: { [mm: string]: T }): MonthlyReportTableData<T>[] {
    return Object.keys(monthlyBreakdown).sort()
        .map(mm => ({
            mm,
            friendlyUpdatedAt: asDate(monthlyBreakdown[mm].transactionsUpdatedAt),
            report: monthlyBreakdown[mm],
        }))
        .filter(e => e.report.report.total > 0)
}

export const MonthlyBreakdown = <T extends MonthlyReport>(props: {
    monthlyBreakdown: { [mm: string]: T },
    renderSummary: (report: T, mm: string) => React.ReactNode,
    renderContents: (report: T, mm: string) => React.ReactNode,
}) => {

    const tableData = asTableData(props.monthlyBreakdown)

    if (isMobile) {

        return <>
            <h2>Monthly breakdown</h2>
            <Collapse
                accordion
                items={tableData.map(d => ({
                    key: d.mm,
                    label: d.mm,
                    children: <>
                        {props.renderSummary(d.report, d.mm)}
                        {props.renderContents(d.report, d.mm)}
                    </>
                }))}
            />
        </>
    }

    return <>
        <h2>Monthly breakdown</h2>
        <Table
            rowKey={t => t.mm} dataSource={tableData}
            pagination={{ pageSize: 12 }}
            expandable={{
                expandedRowRender: row => props.renderContents(row.report, row.mm),
                rowExpandable: row => true
            }}
        >
            <Table.Column title="Month" dataIndex={['mm']} key="mm" />
            <Table.Column title="Totals" key="totals" render={(tableDataRow: MonthlyReportTableData<T>) => props.renderSummary(tableDataRow.report, tableDataRow.mm)} />
            <Table.Column title="Last Recalculated" dataIndex={['friendlyUpdatedAt']} key="lastRecalculated" />
        </Table>
    </>
}
