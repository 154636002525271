import { Button, Card, Divider, DatePicker, Input, Form, Checkbox, CheckboxProps, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReportsComponent } from './ReportsComponent';
import { DisplayCategories } from './CategoryEdit';
import { RuleCategoryDefinition, TransactionApiV2, UpdateTransactionMetadataRequest } from '../api/TransactionApi';
import { logger } from '../utils';
import Chart from 'react-google-charts';
import dayjs from 'dayjs';
import { MonthlyBreakdown, TransactionsTable } from './MobileFriendly';
import { RouteComponentProps } from 'wouter';

const { RangePicker } = DatePicker;

export interface YearlyOutgoingReportComponentProps {
    accountId: string
}

export interface YearlyOutgoingReportComponentState {
    rules?: RuleCategoryDefinition[]
    loadedAccountId?: string;
}

interface YearlyOutgoingReport {
    monthlyBreakdown: { [mm: string]: MonthlyOutgoingReport };
    overall: {
        categories?: CategoryAmount[],
        total?: number,
    };
}

interface CategoryAmount {
    category: string
    total: number
    breakdown: CategoryBreakdown
}


interface CategoryBreakdown { [key: string]: number }

interface MonthlyOutgoingReport {
    report: {
        categories: CategoryAmount[],
        total: number,
        remaining?: ReportTransaction[]
        transactions?: ReportTransaction[]
    }
    transactionsUpdatedAt: string
}

interface ReportTransaction {
    id: string
    timestamp: string
    info: string
    description: string
    amount: number // credit transfers are +ve
    category: string;
    subCategory: string;
    metadata?: { [key: string]: string }
}

let gbpFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});
function asGbp(amount: number | undefined): string {
    if (amount === undefined) return 'No Value'
    return gbpFormat.format(amount)
}

const UpdateTransactionMetadata = (props: {
    categories: string[],
    transaction: ReportTransaction,
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void
}) => {
    const [selectedCategory, setSelectedCategory] = useState(props.categories[0] || '')

    const currentMetadata = props.transaction.metadata || {}

    return <div>
        <b>Current metadata</b>
        {Object.keys(currentMetadata)
            .map(key => <div key={key}><b>{key}</b> = {currentMetadata[key]}</div>)}
        <div>
            <span>Update metadata for</span>
            <Select
                value={selectedCategory}
                style={{ width: 120 }}
                onChange={val => setSelectedCategory(val)}
                options={props.categories.map(category => ({ value: category, label: category }))}
            />
            <UpdateTransactionMetadataForCategory
                categoryName={selectedCategory}
                currentValue={currentMetadata[selectedCategory] || ''}
                updateMetadataValue={metadataValue => props.updateMetadata({
                    yyyyMm: props.transaction.timestamp.substring(0, 7),
                    transactionId: props.transaction.id,
                    metadataKey: selectedCategory,
                    metadataValue,
                })}
            />
        </div>
    </div>
}

const UpdateTransactionMetadataForCategory = (props: {
    categoryName: string,
    currentValue: string,
    updateMetadataValue: (updatedMetadata: string) => void
}) => {
    const [value, updateValue] = useState(props.currentValue)

    useEffect(() => {
        updateValue(props.currentValue);
    }, [props.currentValue])

    return <>
        <div>
            <div>Add one off {props.categoryName} transaction</div>
            <Input style={{ marginLeft: '10px', marginRight: '10px' }} value={value} onChange={val => {
                val.preventDefault()
                const value = (val.target as any).value
                updateValue(value)
            }} />
            <Button onClick={() => props.updateMetadataValue(value)}>Update</Button>
        </div>
    </>
}

const OutgoingTransactionsTable = (params: {
    transactions: ReportTransaction[],
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void
}) => {

    const expectedCategories = ['holiday', 'house', 'beersbeersbeers', 'savings', 'shopping', 'baby', 'travel']

    return <TransactionsTable
        showSubCategory={true}
        transactions={params.transactions}
        renderEditable={row => <UpdateTransactionMetadata
            categories={expectedCategories}
            transaction={row}
            updateMetadata={update => params.updateMetadata(update)}
        />}
    />
}

const CreateHoliday = (params: {
    yyyyMm: string,
    onCreate: (fromDate: string, toDate: string, holiday: string) => void
}) => {
    const expectedYyyy = +params.yyyyMm.substring(0, 4)
    const expectedMm = +params.yyyyMm.substring(5, 7)

    function datesAreValid(dates: dayjs.Dayjs[]): Promise<void> {
        const [fromDate, toDate] = dates
        if (!fromDate || !toDate) {
            return Promise.reject(new Error(`Both dates are required`))
        }
        if (fromDate.year() !== expectedYyyy
            || toDate.year() !== expectedYyyy
            || fromDate.month() + 1 !== expectedMm
            || toDate.month() + 1 !== expectedMm) {
            return Promise.reject(new Error(`Both dates must be in ${params.yyyyMm}`))
        }

        return Promise.resolve()
    }

    const dateFormat = 'YYYY-MM-DD'
    return <>
        <Form layout="horizontal"
            initialValues={{ dateRange: [dayjs(params.yyyyMm, 'YYYY-MM')] }}
            onFinish={({ dateRange, holidayName }: { dateRange: dayjs.Dayjs[], holidayName: string }) => {
                const [fromDate, toDate] = dateRange
                params.onCreate(fromDate.format(dateFormat), toDate.format(dateFormat), holidayName)
            }}
            labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
            <Form.Item label="Date" name="dateRange" rules={[
                { required: true, message: 'Date is required' },
                { validator: (_, value: dayjs.Dayjs[]) => datesAreValid(value) },
            ]}>
                <RangePicker format={dateFormat} />
            </Form.Item>
            <Form.Item label="Holiday Name" name="holidayName" rules={[{ required: true, message: 'Holiday Name is required' }]}>
                <Input placeholder="Holiday name" />
            </Form.Item>
            <Form.Item name="submit" wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">Add</Button>
            </Form.Item>
        </Form >
    </>
}

const reportName = 'OutgoingTransactions'

const MonthlyBreakdownRowExpander = (params: {
    yyyyMm: string,
    remaining: ReportTransaction[],
    transactions: ReportTransaction[],
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void
}) => {
    const [showAll, setShowAll] = useState(false)
    const [showAddHoliday, setShowAddHoliday] = useState(false)

    return <>
        {!showAll && <> <h3>Unmapped transactions <Button onClick={() => setShowAll(true)}>Show all</Button></h3>
            {!showAddHoliday && <Button onClick={() => setShowAddHoliday(true)}>Add holiday</Button>}
            {showAddHoliday && <>
                <h4>Add holiday</h4>
                <CreateHoliday
                    yyyyMm={params.yyyyMm}
                    onCreate={(fromDate, toDate, holiday) => {
                        params.updateMetadata({
                            fromDate,
                            toDate,
                            metadataKey: 'holiday',
                            metadataValue: holiday,
                        })
                        setShowAddHoliday(false)
                    }}
                />
            </>}
            {<OutgoingTransactionsTable
                transactions={params.remaining || []}
                updateMetadata={(...args) => params.updateMetadata(...args)}
            />}
        </>}
        {showAll && <> <h3>All transactions <Button onClick={() => setShowAll(false)}>Show unmapped only</Button></h3>
            {<OutgoingTransactionsTable
                transactions={params.transactions || []}
                updateMetadata={(...args) => params.updateMetadata(...args)}
            />}
        </>}
    </>
}

const MonthlyOutgoingBreakdown = (props: {
    yyyy: string,
    monthlyBreakdown: { [mm: string]: MonthlyOutgoingReport },
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void
}) => {

    return <MonthlyBreakdown
        monthlyBreakdown={props.monthlyBreakdown}
        renderSummary={d => <>
            {d.report.categories.map(({ category, total }) =>
                <div key={category}><b>{category}</b> = {asGbp(total)}</div>)
            }</>
        }
        renderContents={(d, mm) => <MonthlyBreakdownRowExpander
            yyyyMm={`${props.yyyy}-${mm}`}
            remaining={d.report.remaining || []}
            transactions={d.report.transactions || []}
            updateMetadata={(...args) => props.updateMetadata(...args)}
        />}
    />
}

const RenderMonthlyAreaCharts = (props: {
    title: string,
    yyyy: string,
    options: string[],
    selectValue: (option: string, mm: string) => number
}) => {

    const today = new Date()
    const isThisYear = props.yyyy === today.getFullYear() + ''

    let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    if (isThisYear) {
        months = months.splice(0, today.getMonth() + 1)
    }

    const data: (string | number)[][] = ['Month', ...months]
        .map(month => [month])
    props.options.forEach(option => {
        data[0].push(option)
        months.forEach((month, idx) => {
            const value = props.selectValue(option, month)
            data[idx + 1].push(value)
        })
    })

    return <>
        <Chart chartType="AreaChart" data={data} options={{
            title: props.title,
            hAxis: { title: "Month", titleTextStyle: { color: "#333" } },
            vAxis: { minValue: 0 },
            chartArea: { width: "70%", height: "70%" },
        }} />
    </>
}

const RenderSelectableCategories = (props: {
    options: string[],
    onSelectedChange: (options: string[]) => void
}) => {
    const [selectedOptions, setSelectedOptions] = useState(props.options)

    useEffect(() => {
        setSelectedOptions(props.options);
    }, [props.options])

    const checkAll = props.options.length === selectedOptions.length;
    const indeterminate = selectedOptions.length > 0 && selectedOptions.length < props.options.length;

    const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
        const updated = e.target.checked ? props.options : [];
        setSelectedOptions(updated);
        props.onSelectedChange(updated);
    };

    return <>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            Check all
        </Checkbox>
        <br />
        <Checkbox.Group
            options={props.options}
            value={selectedOptions}
            onChange={vals => {
                setSelectedOptions(vals)
                props.onSelectedChange(vals)
            }}
        />
    </>
}

const RenderOverallCharts = (props: {
    allCategories: string[],
    report: YearlyOutgoingReport,
    yyyy: string,
}) => {

    const [selectedCategories, setSelectedCategories] = useState(props.allCategories)

    if (props.report.overall.categories === undefined
        || Object.keys(props.report.overall.categories).length === 0) {
        return <></>
    }

    return <>
        <Chart
            chartType="PieChart"
            data={[
                ["Category", "Total"],
                ...(props.report.overall.categories || [])
                    .filter(c => !!selectedCategories.find(o => o === c.category))
                    .map(({ category, total }) => ([category, total]))
            ]}
            options={{
                title: "Category breakdown",
            }}
            width={"100%"}
            height={"400px"}
        />
        <RenderSelectableCategories
            options={props.allCategories}
            onSelectedChange={vals => setSelectedCategories(vals)} />
        <RenderMonthlyAreaCharts
            title='Categories by month'
            yyyy={props.yyyy}
            options={selectedCategories}
            selectValue={(category, month) => {
                const monthCategories = props.report.monthlyBreakdown[month].report
                    .categories.find(c => c.category === category)
                if (!monthCategories) return 0
                return monthCategories.total
            }}
        />
    </>
}

const RenderSelectedCategoryAreaCharts = (props: {
    yyyy: string,
    selectedCategory: string,
    subCategories: string[],
    monthlyBreakdown: { [mm: string]: MonthlyOutgoingReport },
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void,
}) => {
    const [selectedSubcategories, setSelectedSubcategories] = useState(props.subCategories)
    const [showTransactions, setShowTransactions] = useState(false)

    useEffect(() => {
        setSelectedSubcategories(props.subCategories);
    }, [props.subCategories])

    const transactionsForCategory: ReportTransaction[] = []
    Object.keys(props.monthlyBreakdown)
        .forEach(mm => {
            const breakdown = props.monthlyBreakdown[mm]
            breakdown.report.transactions?.forEach(t => {
                if (t.category === props.selectedCategory) {
                    if (!!selectedSubcategories.find(c => c === (t.subCategory || '<empty>'))) {
                        transactionsForCategory.push(t)
                    }
                }
            })
        })

    return <>
        <RenderSelectableCategories
            options={props.subCategories}
            onSelectedChange={vals => setSelectedSubcategories(vals)} />
        <RenderMonthlyAreaCharts
            title={`Subcategories for ${props.selectedCategory} by month`}
            yyyy={props.yyyy}
            options={selectedSubcategories}
            selectValue={(subCategory, month) => {
                const monthCategories = props.monthlyBreakdown[month].report.categories.find(c => c.category === props.selectedCategory)
                if (!monthCategories) return 0
                return monthCategories.breakdown[subCategory] || 0
            }}
        />
        <Button onClick={() => setShowTransactions(!showTransactions)}>{showTransactions ? 'Hide transactions' : 'View transactions'}</Button>
        {showTransactions && <OutgoingTransactionsTable
            transactions={transactionsForCategory}
            updateMetadata={(...args) => props.updateMetadata(...args)}
        />}
    </>
}

const RenderCategoryAreaCharts = (props: {
    report: YearlyOutgoingReport,
    yyyy: string,
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void,
}) => {
    const categories = (props.report.overall.categories || []).map(c => c.category)
    const [selectedCategory, setSelectedCategory] = useState(categories[0])

    if (props.report.overall.categories === undefined
        || props.report.overall.categories.length === 0) {
        return <></>
    }

    const category = props.report.overall.categories.find(c => c.category === selectedCategory);

    return <>
        <Radio.Group
            options={categories}
            value={selectedCategory}
            onChange={category => setSelectedCategory(category.target.value)}
            optionType="button"
            buttonStyle="solid"
        />
        <Divider />
        {!!category && <RenderSelectedCategoryAreaCharts
            yyyy={props.yyyy}
            selectedCategory={category.category}
            subCategories={Object.keys(category.breakdown)}
            monthlyBreakdown={props.report.monthlyBreakdown}
            updateMetadata={props.updateMetadata}
        />}
        {/* <RenderSelectableCategories
            options={allCategories.map(c => c.category)}
            onSelectedChange={vals => setSelectedCategories(allCategories
                .filter(c => !!vals.find(v => v === c.category)))} />
        {!!category && <RenderMonthlyAreaCharts
            title={`Subcategories for ${selectedCategory} by month`}
            yyyy={props.yyyy}
            options={ }
            selectValue={(subCategory, month) => {
                const monthCategories = props.report.monthlyBreakdown[month].report.categories.find(c => c.category === selectedCategory)
                if (!monthCategories) return 0
                return monthCategories.breakdown[subCategory] || 0
            }}
        />} */}
    </>
}


export const YearlyOutgoingReportComponent = (props: RouteComponentProps<{ accountId: string }>) => {

    const { accountId } = props.params

    if (!accountId) {
        return <>No account ID selected</>
    }

    return <YearlyOutgoingReportInnerComponent accountId={accountId} />
}

export class YearlyOutgoingReportInnerComponent extends React.Component<YearlyOutgoingReportComponentProps, YearlyOutgoingReportComponentState> {

    constructor(props: YearlyOutgoingReportComponentProps) {
        super(props)
        this.state = {
        };
    }

    getRules(accountId: string) {
        console.log("getting rules for", accountId, reportName)
        TransactionApiV2.rules.get(accountId, reportName)
            .then(response => {
                logger.info('got rules', response);
                this.setState({
                    rules: response.data.rules,
                    loadedAccountId: accountId,
                });
            })
            .catch(err => {
                logger.error('get rules error', err);
            });
    }

    updateRule(rule: RuleCategoryDefinition) {
        const { loadedAccountId, } = this.state
        logger.info("updating rules for", loadedAccountId, reportName, rule)
        if (!loadedAccountId) return
        TransactionApiV2.rules.post(loadedAccountId, reportName, rule)
            .then(response => {
                logger.info('updated', response);
                this.getRules(loadedAccountId)
            })
            .catch(err => {
                logger.error('update rule error', err);
            });
    }

    render() {
        const { accountId } = this.props
        const { rules, loadedAccountId } = this.state

        if (loadedAccountId !== accountId) {
            this.getRules(accountId)
            return <>Waiting</>
        }

        return <ReportsComponent<YearlyOutgoingReport>
            accountId={accountId}
            reportName={reportName}
            renderComponent={({ report, updateMetadata, yyyy }) => <>
                <h2>Analysis</h2>
                <h3>Overall</h3>
                <Card>
                    <div><b>Total</b> = {asGbp(report.overall.total)}</div>
                    {/* <div><b>Category breakdown</b>
                        <ul>
                            {Object.keys(report.overall.categories)
                                .sort((a, b) => report.overall.categories[b] - report.overall.categories[a])
                                .map(category => <li key={category}>{category} = {asGbp(report.overall.categories[category])} ({asPercentage(report.overall.categories[category], report.overall.total)})</li>)}
                        </ul>
                    </div> */}
                    <RenderOverallCharts
                        yyyy={yyyy}
                        report={report}
                        allCategories={(report.overall.categories || []).map(c => c.category)} />
                </Card>

                <h3>By Category</h3>
                <Card>
                    <RenderCategoryAreaCharts report={report} yyyy={yyyy} updateMetadata={updateMetadata} />
                </Card>

                <Divider />

                <h2>Category Mapping</h2>
                <DisplayCategories
                    rules={rules || []}
                    updateRule={rule => this.updateRule(rule)}
                />

                <Divider />


                <MonthlyOutgoingBreakdown
                    yyyy={yyyy}
                    monthlyBreakdown={report.monthlyBreakdown}
                    updateMetadata={updateMetadata}
                />
            </>}
        />
    }
}
