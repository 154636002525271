import { ReactElement } from 'react';
import { BankOutlined, BookOutlined, UserOutlined } from '@ant-design/icons';

interface MenuDefinition {
  title: string,
  icon?: ReactElement,
  menuitems?: { title: string, url?: string }[]
  url?: string,
}

export class SideMenu {

  buildMenu(
    accounts: { id: string, name: string }[],
    accountsV2: { id: string, name: string }[]) {
    const menu: MenuDefinition[] = [{
      title: 'Home',
      url: `/`
    }, {
      title: 'V2 Incoming Report',
      icon: <BookOutlined />,
      menuitems: accountsV2.map(account => ({
        account_id: account.id,
        title: account.name || account.id,
        url: `/v2/incoming/${account.id}`,
      }))
    }, {
      title: 'V2 Outgoing Report',
      icon: <BookOutlined />,
      menuitems: accountsV2.map(account => ({
        account_id: account.id,
        title: account.name || account.id,
        url: `/v2/outgoing/${account.id}`,
      }))
    }
      // , {
      //   title: 'Raw Transactions',
      //   icon: <BankOutlined />,
      //   menuitems: accounts.map(account => ({
      //     account_id: account.id,
      //     title: account.name || account.id,
      //   }))
      // }, {
      //   title: 'Mapped Transactions',
      //   icon: <UserOutlined />,
      //   menuitems: accounts.map(account => ({
      //     account_id: account.id,
      //     title: account.name || account.id,
      //   }))
      // }, {
      //   title: 'Manage Account Rules',
      //   icon: <BookOutlined />,
      //   menuitems: accounts.map(account => ({
      //     account_id: account.id,
      //     title: account.name || account.id,
      //   }))
      // }
    ]

    return { menu }
  }
}
