import React from 'react';
import { Button, Card, Input } from 'antd';
import { CancelToken } from '../api/HttpClient';
import { AccessTokenInfo, BankLoginStatusV2, TransactionApiV2 } from '../api/TransactionApi';
import { logger } from '../utils';

interface AccessTokenComponentProps {
    yyyy: string;
    accessToken: AccessTokenInfo;
    recreateReport: () => void;
    reloadAccessToken: () => void;
}

interface AccessTokenComponentState {
    loginStatus?: BankLoginStatusV2
    error?: string
    updatingTransactions: boolean
    attemptedRefreshError?: string
    isRefreshing: boolean
    reloading: boolean
    reloadDate: string
    editReloadDate: boolean
}

class AccessTokenComponent extends React.Component<AccessTokenComponentProps, AccessTokenComponentState> {
    constructor(props: AccessTokenComponentProps) {
        super(props)

        const today = new Date()
        const thisMonth = ((today.getMonth() + 1) + '').padStart(2, '0')
        this.state = {
            updatingTransactions: false,
            isRefreshing: false,
            reloading: false,
            reloadDate: thisMonth,
            editReloadDate: false,
        };
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    refresh() {
        const { accessToken, reloadAccessToken } = this.props
        const { isRefreshing } = this.state
        if (isRefreshing) return
        this.setState({ isRefreshing: true })
        TransactionApiV2.bank.refresh(accessToken.account_id)
            .then(response => {
                if (response.statusCode === 200) {
                    reloadAccessToken()
                } else {
                    this.setState({ isRefreshing: false, attemptedRefreshError: (response.data as any).error_code || ':(' })
                }
            })
            .catch(err => {
                logger.error('exchangeCode error', err);
            });
    }

    reloadTransactions(reloadMm: string) {
        if (/^\d{2}$/.exec(reloadMm) == null || +reloadMm < 1 || +reloadMm > 12) {
            console.log("Must be 01 to 12", reloadMm)
            return
        }

        const { accessToken, recreateReport, yyyy } = this.props
        this.setState({ reloading: true })
        const reloadYyyyMm = `${yyyy}-${reloadMm}`
        console.log("reloading", reloadYyyyMm)
        TransactionApiV2.bank.updateTransactions(accessToken.account_id, reloadYyyyMm)
            .then(response => {
                logger.info('got latest transactions', response);
                this.setState({ reloading: false })
            })
            .catch(err => {
                logger.error('updateTransactions error', err);
            })
            .finally(() => {
                recreateReport()
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    render() {
        const { accessToken } = this.props
        const {
            error,
            attemptedRefreshError,
            isRefreshing,
            reloading,
            reloadDate,
            editReloadDate, } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        const accessTokenDate = new Date(accessToken.access_token_expiry)
        const accessTokenExpired = new Date().getTime() - accessTokenDate.getTime() > 0
        const accesstokenExpiry = accessTokenDate.toLocaleString()

        return <div>
            {accessTokenExpired && <>
                <div>Access token expired at <b>{accesstokenExpiry}</b></div>
                {!attemptedRefreshError && <>
                    {isRefreshing && <div>waiting</div>}
                    {!isRefreshing && <Button onClick={() => this.refresh()}>Refresh Token</Button>}
                </>}
                {!!attemptedRefreshError && <>
                    <div>Could not refresh: {attemptedRefreshError}</div>
                    <div>Create new access token below</div>
                </>}
            </>}
            {!accessTokenExpired && <>
                <div>Access token expires at <b>{accesstokenExpiry}</b></div>
                <Button disabled={reloading} onClick={() => this.reloadTransactions(reloadDate)}>Reload transactions for {reloadDate}</Button>
                <Button onClick={() => this.setState({ editReloadDate: !editReloadDate })}>edit</Button>
                {editReloadDate && <Input style={{ width: '20%' }} value={reloadDate} onChange={val => {
                    val.preventDefault()
                    const value = (val.target as any).value
                    this.setState({ reloadDate: value })
                }} />}
            </>}
        </div>
    }
}

export interface ReportAccessTokenComponentProps {
    yyyy: string;
    accountId: string;
    recreateReport: () => void;
}

export interface ReportAccessTokenComponentState {
    loginStatus?: BankLoginStatusV2
    error?: string
    updatingTransactions: boolean
    attemptedRefreshError?: string
}

export class ReportAccessTokenComponent extends React.Component<ReportAccessTokenComponentProps, ReportAccessTokenComponentState> {
    constructor(props: ReportAccessTokenComponentProps) {
        super(props)
        this.state = {
            updatingTransactions: false,
        };
    }

    componentDidMount(): void {
        this.loadLoginStatus()
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    loadLoginStatus() {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        TransactionApiV2.bank.loginStatus(this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    this.setState({
                        loginStatus: response.data
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    render() {
        const { accountId, yyyy, recreateReport } = this.props
        const { loginStatus, error } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (!loginStatus) {
            return <>Waiting</>
        }

        const foundAccount = loginStatus.accounts.find(a => a.account_id === accountId)

        return <>
            {!!foundAccount && <AccessTokenComponent
                key={foundAccount.account_id}
                yyyy={yyyy}
                accessToken={foundAccount}
                recreateReport={recreateReport}
                reloadAccessToken={() => this.loadLoginStatus()} />}
            {!foundAccount && <div>Account not found, try linking a new token</div>}
        </>
    }
}
