import React, { useSyncExternalStore } from 'react';
import './App.css';
import { Layout, Menu, MenuProps } from 'antd';
import { TransactionApi, TransactionApiV2 } from './api/TransactionApi';
import { CancelToken } from './api/HttpClient';
import { SideMenu } from './SideMenuDefinition';
import { RoutePageComponent } from './RoutePage';
import { logger, isMobile } from './utils';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export const AccountsContext = React.createContext({ accounts: undefined as { id: string; name: string; }[] | undefined });

export interface AppComponentProps {
}

export interface AppComponentState {
  accounts: { id: string, name: string }[] | undefined
  accountsV2: { id: string, name: string }[] | undefined
}

export class App extends React.Component<AppComponentProps, AppComponentState> {

  constructor(props: AppComponentProps) {
    super(props)
    this.state = {
      accounts: [],
      accountsV2: [],
    };

    this.cancelToken = new CancelToken();
    this._isCanceled = false
  }

  private cancelToken: CancelToken
  private _isCanceled: boolean

  componentDidMount() {
    this.cancelToken = new CancelToken();
    this._isCanceled = false
    TransactionApi.accounts(this.cancelToken)
      .then(response => {
        this.setState({
          accounts: response.data?.results.accounts
        });
      })
      .catch(err => {
        if (!this._isCanceled) {
          logger.warn('get accounts cancelled', err);
        }
      });
    TransactionApiV2.accounts(this.cancelToken)
      .then(response => {
        this.setState({
          accountsV2: response.data?.accounts
        });
      })
      .catch(err => {
        if (!this._isCanceled) {
          logger.warn('get accounts cancelled', err);
        }
      });
  }

  componentWillUnmount() {
    this._isCanceled = true;
    this.cancelToken.cancel();
  }

  render() {
    const { accounts, accountsV2 } = this.state
    const { menu } = new SideMenu().buildMenu(accounts || [], accountsV2 || [])

    const navMenuItems: Required<MenuProps>['items'] = menu.map((item, index) => ({
      label: !!item.url ? <a href={item.url}>
        {item.title}
      </a> : item.title,
      key: index,
      children: item.menuitems?.map((menuItem, menuIdx) => ({
        label: !!menuItem.url ? <a href={menuItem.url}>
          {menuItem.title}
        </a> : menuItem.title,
        key: `${index}-${menuIdx}`
      })),
    }))

    return <Layout>
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]}
          items={[{
            label: 'Account Tracker', key: '1',
            children: isMobile ? navMenuItems.map(i => ({ type: 'group', ...i })) : [],
          }]}
        />
      </Header>
      <Layout>
        {!isMobile && <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['0']}
            defaultOpenKeys={[]}
            style={{ height: '100%', borderRight: 0 }}
            items={navMenuItems}
          />
        </Sider>}
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: isMobile ? 0 : 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <AccountsContext.Provider value={{ accounts: accountsV2 }}>
              <RoutePageComponent />
            </AccountsContext.Provider>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  }
}

export default App;