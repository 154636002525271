import React from 'react';
import {
  CategorisedTransactionsComponent,
  HomePageComponent,
  ManageRulesComponent,
  UncategorisedTransactionsComponent,
  YearlyIncomingReportComponent,
  YearlyOutgoingReportComponent,
} from './Pages';
import { Link, Route, Switch } from "wouter";

export interface RoutePageComponentProps {
}

export interface RoutePageComponentState {
}

export class RoutePageComponent extends React.Component<RoutePageComponentProps, RoutePageComponentState> {

  componentDidMount() {
  }

  onSearch(id: string) {
    this.setState({ accountId: id })
  }

  render() {

    return (<>
      <Switch>
        <Route path="/" component={HomePageComponent} />
        <Route path="/v2/incoming/:accountId" component={YearlyIncomingReportComponent} />
        <Route path="/v2/outgoing/:accountId" component={YearlyOutgoingReportComponent} />
      </Switch>
      {/* {selectedMenuItem.breadcrumb[0] === 'Home' && <HomePageComponent />}
      {!!selectedMenuItem.accountId && <>
        {selectedMenuItem.breadcrumb[0] === 'Raw Transactions' && <UncategorisedTransactionsComponent accountId={selectedMenuItem.accountId} />}
        {selectedMenuItem.breadcrumb[0] === 'Mapped Transactions' && <CategorisedTransactionsComponent accountId={selectedMenuItem.accountId} />}
        {selectedMenuItem.breadcrumb[0] === 'Manage Account Rules' && <ManageRulesComponent accountId={selectedMenuItem.accountId} />}
        {selectedMenuItem.breadcrumb[0] === 'V2 Incoming Report' && }
        {selectedMenuItem.breadcrumb[0] === 'V2 Outgoing Report' && <YearlyOutgoingReportComponent accountId={selectedMenuItem.accountId} />}
      </>} */}
    </>);
  }
}
